// @ts-check
import { maxLength, required } from '@vuelidate/validators';
import { isFromRemote } from '@common/Helpers/api';
import { randomString } from '@common/Helpers/strings';
import { utcToLocal } from '@common/Helpers/time';
import moment from 'moment';
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';
import { sortByKey } from '@common/Helpers/sorters';

export class SymptomModel {
  constructor({
    Id = randomString(), // Temp ID needed when referring new symptoms on manikin.
    Name,
    Description,
    Model,
    CreatedDate,
    ModifiedDate,
    ClosedDate,
    Reports = [],
    Version
  }) {
    this._modelName = 'Symptom';
    this.Id = Id;
    this.Name = Name || '';
    this.Description = Description || '';
    this.Model = Model || 'male';
    this.CreatedDate = CreatedDate ? utcToLocal(CreatedDate) : moment();
    this.ModifiedDate = ModifiedDate
      ? utcToLocal(ModifiedDate)
      : this.CreatedDate;
    this.ClosedDate = ClosedDate ? utcToLocal(ClosedDate) : null;
    this.Reports = Reports.map(r => new SymptomReportSymptomModel(r));
    this.Reports = sortByKey(this.Reports, 'CreatedDate');
    this.Version = Version || 0;

    this._saved = !!CreatedDate;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  get lastReport() {
    const reports = sortByKey(this.Reports, 'CreatedDate');
    if (reports.length === 0) {
      return null;
    }

    const report = reports.slice().pop();
    return report;
  }

  get isClosed() {
    return this.ClosedDate !== null && this.ClosedDate !== undefined;
  }

  get isFromRemote() {
    return isFromRemote(this);
  }

  static get validations() {
    return {
      Name: {
        required,
        maxLength: maxLength(60)
      }
    };
  }
}

export default SymptomModel;
